<template>
<v-data-table class="elevation-1" :items="data" :headers="headers">
    <template v-slot:item.vigencia="{ item }">
        {{ item.inicio }} - {{ item.fim }}
    </template>
    <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on" v-if="$state.user.is_superuser" small class="mr-2" @click="edit(item)">
                    mdi-pencil
                </v-icon>
            </template>
            <span>Editar</span>
        </v-tooltip>

        <MembrosModal :pdti="item" />
    </template>
    <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>PDTICs</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="600px" @click:outside="close">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if="$state.user.is_superuser" color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Novo</v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline">Iniciar Novo PDTIC</span>
                    </v-card-title>
                    <ValidationObserver ref="form" v-slot="{ passes }">
                        <v-form @submit.prevent="passes(save)" width="100%">
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <ValidationProvider vid="descricao" name="Descrição" v-slot="{ errors }" rules="required">
                                            <v-text-field v-model="Item.descricao" label="Descrição" :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-row>
                                    <v-col>
                                        <ValidationProvider vid="inicio" name="Início" v-slot="{ errors }" rules="required">
                                            <v-text-field v-model="Item.inicio" label="Início" type="number" :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col>
                                        <ValidationProvider vid="fim" name="Fim" v-slot="{ errors }" rules="required">
                                            <v-text-field v-model="Item.fim" label="Fim" type="number" :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <ValidationProvider vid="inicio_cadastro" name="Inicio da fase de Elaboração" v-slot="{ errors }" rules="required">
                                        <v-row justify="center">
                                            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="Item.inicio_cadastro" label="Início da fase de Elaboração" prepend-icon="mdi-calendar" :error-messages="errors" v-bind="attrs" v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="Item.inicio_cadastro"></v-date-picker>
                                            </v-menu>
                                        </v-row>
                                    </ValidationProvider>
                                    <ValidationProvider vid="fim_cadastro" name="Fim da fase de Elaboração" v-slot="{ errors }" rules="required">
                                        <v-row justify="center">
                                            <v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="Item.fim_cadastro" label="Fim da fase de Elaboração" prepend-icon="mdi-calendar" :error-messages="errors" v-bind="attrs" v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="Item.fim_cadastro"></v-date-picker>
                                            </v-menu>
                                        </v-row>
                                    </ValidationProvider>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color=" darken-1" text @click="close">Cancelar</v-btn>
                                <v-btn :loading="saving" color="darken-1" text @click="save">Salvar</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </v-toolbar>
    </template>
</v-data-table>
</template>

<script>
import {
    PDTIC
} from "@/api/core";
import MembrosModal from "@/views/admin/MembrosModal";
export default {
    name: "PDTIC",
    components: {
        MembrosModal,
    },
    data: () => ({
        data: [],
        headers: [{
                text: "Vigência",
                value: "vigencia",
                align: "center",
            },
            {
                text: "Ações",
                value: "actions",
                sortable: false,
                align: "end",
            },
        ],
        Item: {
            inicio: "",
            fim: "",
        },
        dialog: false,
        saving: false,
        errors: [],
    }),
    methods: {
        close() {
            this.dialog = false;
            this.$refs.form.reset();
            this.$nextTick(() => {
                this.Item = {};
            });
        },
        save() {
            this.$refs.form
                .validate()
                .then((valid) => {
                    if (valid) {
                        this.saving = true;
                        const _then = () => {
                            this.saving = false;
                            this.close();
                            this.fetch();
                        };
                        const _catch = (error) => {
                            this.saving = false;
                            this.$refs.form.setErrors(error.response.data);
                            if (this.errors["non_field_errors"]) {
                                this.$emitter.emit(
                                    "alert:error",
                                    this.errors["non_field_errors"][0]
                                );
                            } else {
                                this.$emitter.emit("alert:error", this.errors);
                            }
                        };

                        if (this.Item["id"]) {
                            let id = this.Item["id"];

                            PDTIC.update(id, this.Item)
                                .then(_then)
                                .catch(_catch);
                        } else {
                            PDTIC.post(this.Item)
                                .then(_then)
                                .catch(_catch);
                        }
                    }
                })
                .catch(console.log);
        },
        edit(item) {
            this.Item = Object.assign({}, item);
            this.dialog = true;
        },
        fetch() {
            PDTIC.list().then((response) => {
                this.data = response.data;
            });
        },
    },
    mounted() {
        this.fetch();
    },
};
</script>
