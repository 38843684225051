<template>
<v-dialog v-model="dialog" max-width="1200px">
    <template v-slot:activator="{ on, attrs }">
        <v-icon small class="mr-2" v-bind="attrs" v-on="on">
            mdi-account-group
        </v-icon>
    </template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ pdti.inicio }} - {{ pdti.fim }}</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col>
                        <v-data-table :headers="headers" :items="data" :loading="tableLoading" class="elevation-1">
                            <template v-slot:item.actions="{ item }">

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" v-if="$state.user.is_superuser" small @click="removeMembro(item)">
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span>Remover membro</span>
                                </v-tooltip>

                            </template>
                            <template v-slot:top>
                                <v-dialog v-model="editDialog" max-width="600px">
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">
                                                {{ Item.user.first_name }}
                                                {{ Item.user.last_name }}</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col>
                                                        <v-row>
                                                            <UOAutoComplete v-model="Item.uo" />
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="darken-1" text @click="close">Cancelar</v-btn>
                                            <v-btn :loading="saving" color="darken-1" text @click="save">Salvar</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                                <v-toolbar flat>
                                    <v-spacer></v-spacer>
                                    <v-spacer></v-spacer>
                                    <v-text-field v-if="$state.user.is_superuser" append-icon="mdi-account-plus" placeholder="Insira o usuário para adicionar um membro" v-model="username" :error-messages="usernameErrors" @click:append="addMembro" @keydown.enter="addMembro" :loading="saving"></v-text-field>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.nome="{ item }">
                                {{ item.user.first_name }} {{ item.user.last_name }}
                            </template>
                            <template v-slot:item.is_membro_comissao="{ item }">
                                <v-checkbox v-model="item.is_membro_comissao" @click="toggleMembro(item, item.is_membro_comissao, 1)"></v-checkbox>
                            </template>
                            <template v-slot:item.is_membro_eqpdti="{ item }">
                                <v-checkbox v-model="item.is_membro_eqpdti" @click="toggleMembro(item, item.is_membro_eqpdti, 2)"></v-checkbox>
                            </template>
                            <template v-slot:item.is_membro_eqpdti_uo="{ item }">
                                <v-checkbox v-model="item.is_membro_eqpdti_uo" @click="toggleMembro(item, item.is_membro_eqpdti_uo, 3)"></v-checkbox>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
import {
    Membro
} from "@/api/core";
import UOAutoComplete from "@/components/UOAutoComplete";
export default {
    name: "MembrosModal",
    components: {
        UOAutoComplete,
    },
    props: {
        pdti: {
            required: true,
        },
    },
    data() {
        return {
            dialog: false,
            editDialog: false,
            saving: false,
            tableLoading: false,
            usernameErrors: [],
            username: "",
            data: [],
            Item: {
                user: {},
            },
            headers: [{
                    text: "Usuário",
                    value: "user.username",
                    align: "center",
                },
                {
                    text: "Nome",
                    value: "nome",
                    align: "center",
                },
                {
                    text: "Comitê de Governança Digital",
                    value: "is_membro_comissao",
                    align: "center",
                },
                {
                    text: "EqPDTIC",
                    value: "is_membro_eqpdti",
                    align: "center",
                },
                {
                    text: "EqPDTIC Local",
                    value: "is_membro_eqpdti_uo",
                    align: "center",
                },
                {
                    text: "Unidade Organizacional",
                    value: "uo.sigla",
                    align: "center",
                },
                {
                    text: "Ações",
                    value: "actions",
                    sortable: false,
                    align: "end",
                },
            ],
        };
    },
    watch: {
        dialog(val) {
            if (val) {
                this.fetch();
            }
        },
    },
    methods: {
        toggleMembro(item, value, tipo) {
            if (tipo == 3 && value) {
                this.Item = Object.assign({
                        value,
                    },
                    item
                );
                this.editDialog = true;
            } else if (value) {
                Membro.add(item.user.username, this.pdti.id, tipo);
            } else {
                Membro.removeRole(item.user.username, this.pdti.id, tipo);
            }
        },
        addMembro() {
            this.saving = true;
            this.usernameErrors = [];
            Membro.add(this.username, this.pdti.id, 1)
                .then(() => {
                    this.fetch();
                    this.username = "";
                })
                .catch((error) => {
                    this.usernameErrors = error.response.data["username"];
                    this.$emitter.emit(
                        "alert:error",
                        "Não foi possível adicionar o membro."
                    );
                })
                .finally(() => (this.saving = false));
        },
        removeMembro(item) {
            Membro.remove(item.id).then(() => {
                this.fetch();
            });
        },
        edit(item) {
            this.Item = Object.assign({}, item);
            this.editDialog = true;
        },
        close() {
            this.editDialog = false;
        },
        save() {
            this.editDialog = false;
            Membro.add(this.Item.user.username, this.pdti.id, 3, this.Item.uo)
                .then(() => this.fetch())
                .catch((error) => {
                    if (error.response.status == 400) {
                        this.$emitter.emit(
                            "alert:error",
                            "Selecione uma unidade organizacional"
                        );
                        this.fetch();
                    }
                });
        },
        fetch() {
            this.tableLoading = true;
            Membro.list({
                pdti: this.pdti.id,
            }).then((response) => {
                this.tableLoading = false;
                this.data = response.data;
            });
        },
    },
};
</script>
